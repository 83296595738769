/* ©2018 EdgeVerve Systems Limited (a fully owned Infosys subsidiary),
Bangalore, India. All Rights Reserved.
 */
import {
    html,
    PolymerElement
} from '@polymer/polymer/polymer-element.js';


const CorpAppStyles = document.createElement('dom-module');

CorpAppStyles.innerHTML =
    `<template>
            <style include="app-styles">   
          
            /*Optimized tab styling*/
            
            .optimized-tab paper-tab {
                --paper-tab: {
                    color: var(--secondary-dark-color);
                    background: var(--primary-light-color);
                    transform: scale(.94, .9);
                    transition: transform .1s ease;
                    max-width: 220px;
                    min-width: 220px;
                }
                --paper-tab-content: {
                    z-index: 1;
                }
                --paper-tab-ink: var(--brand-color);
            }
            
            .optimized-tab paper-tab {
                border: 1px solid var(--divider-color);
                --paper-tab-content-focus: {
                    color: red;
                }
            }
            
            paper-tabs.optimized-tab {
                --paper-tabs-selection-bar-color: transparent;
                font-size: unset;
                height: 116px;
                letter-spacing: 1.33px;
            }
            
            paper-tabs.optimized-tab paper-tab.iron-selected {
                background: var(--brand-color-100);
                transform: scale(1);
                transition: transform .1s ease;
                color: var(--primary-light-color);
                font-size: unset;
                margin: 0;
                border: none;
                max-width: 220px;
                min-width: 220px;

            }
            
            /*Optimized tab styling*/
            
            
            
            
            paper-progress.brand-color {
                --paper-progress-active-color: var(--brand-color);
                --paper-progress-secondary-color: var(--divider-color);
                height: 3px;
                width: 115px;
            }           
        
			.brand-background-50 {
                background-color: var(--brand-color-50);
            }
            
            .brand-background-100 {
                background-color: var(--brand-color-100);
            }
            
            .brand-background-200 {
                background-color: var(--brand-color-200);
            }
            
            
            
            /*Corp accordian styling*/            
                paper-item {
                    --paper-item: {
                        font-size: inherit;
                    }
                    --paper-item-focused-after: {
                        background: transparent;
                    }
                    --paper-item-focused-before: {
                        background: transparent;
                    }
                }

                paper-submenu, paper-menu {
                    --paper-menu: {
                        font-size: inherit;
                    }
                    --paper-menu-focused-item-after: {
                        background: transparent;
                    }
                    --paper-menu-focused-item-before: {
                        background: transparent;
                    }
                    --paper-menu-selected-item: {
                        font-weight: inherit;
                    }
                }

                .accordian-menu .menu-content {
                    position: relative;
                    z-index: 1;
                }

                .critical-item {
                    --paper-menu-selected-item: {
                        font-weight: inherit;
                        background: var(--critical-color-100);
                        color: var(--primary-light-color);
                        border: none;
                    }
                }

                .accordian-menu paper-submenu {
                    --paper-menu-selected-item: {
                        font-weight: inherit;
                        background: var(--brand-color-100);
                        color: var(--primary-light-color);
                        border: none;
                    }
                    --paper-menu-focused-item-after: {
                        background: transparent;
                    }
                }

                .accordian-icon {
                    width: 64px;
                    height: 100%;
                }

                .currency-selector-alignment {
                    --card-align-center: {
                        left: 0;
                        right: 0;
                        margin: auto;
                    }
                }
                /*Corp accordian styling*/

                .banner-background {
                    background-image: var( --dashboard_bg_texture);;
                    background-size: 100% 85%;
                    background-repeat: no-repeat;
                    background-position-y: bottom;
                }
;

                .brand-background-100 {
                    background: var(--brand-color-100);
                }

                .brand-background-200 {
                    background: var(--brand-color-200);
                }
                /*Tab 1 menu styling*/

                .menu-tabs {
                    padding-right: 35px;
                    font-size: var(--a-font-size-m);
                    color: var(--secondary-dark-color);
                    text-transform: uppercase;
                    transition: transform .1s ease;
                }
                
                :dir(rtl) .menu-tabs {
                    padding-left: 35px;
                    font-size: var(--a-font-size-m);
                    color: var(--secondary-dark-color);
                    text-transform: uppercase;
                    transition: transform .1s ease;
                }

                .menu-tabs[selected] {
                    transition: transform .1s ease;
                    color: var(--brand-color-200);
                    font-family: var(--medium-font) !important;
                }

                :dir(rtl) .menu-tabs[selected] {
                    transition: transform .1s ease;
                    color: var(--brand-color-200);
                    font-family: var(--medium-font) !important;
                }
                /*Tab 1 menu styling*/
                /*Tab 2 menu styling*/

                paper-tabs.menu-tabs-2 .iron-selected {
                    color: var(--primary-light-color);
                    opacity:1.0;
                    background: var(--brand-color-200);
                }
                .menu-tabs-2 paper-tab:hover{  
                    color: var(--primary-light-color);
                    opacity:0.8;
                }
                paper-tabs.menu-tabs-2 .iron-selected:hover{
                    color: var(--primary-light-color) !important;
                    opacity:0.8;                 
                }
               

                .menu-tabs-2 paper-tab {
                    --paper-tab: {
                        font-size: var(--a-font-size-m);
                        color:var(--primary-light-color);
                        margin-left:0px;
                        font-family: var(--medium-font);
                    }
                }
                
                /* below class styling for secondary tabs eg: counterparties, payments tabs in pending approval*/
                
                .tabs-brand-background-100 {
                    background: var(--brand-color-80);
                }
            
                paper-tabs.menu-tabs-2 {
                    --paper-tabs-selection-bar-color: var(--brand-color-100);
                    font-size: inherit;
                    height:36px;
                }
                /*Tab 2 menu styling*/

            
                /*Side nav Styling*/
                .side-nav paper-menu {                                     
                    --paper-menu-background-color: var(--primary-light-color);
                    --paper-menu-selected-item:{
                        background-color:var( --background-primary);
                    };
                    --paper-menu-focused-item-after:{
                        background-color:var( --background-primary);
                    }
                }
                .side-nav paper-item {
                --paper-item:{
                        color: var(--primary-dark-color);
                        font-size: var(--a-font-size-m);
                    };
                    --paper-item-focused-before:{
                        background-color:var( --background-primary);
                    }
                }
                .side-nav .selected-div {
                            width:4px;   
                }
                .side-nav .selected-div[selected]{
                    background-color:var(--secondary-brand-color); 
                }
                /*Side nav Styling*/
            
                .primary-background-200 {
                    background: var(--primary-color-200);
                }

                .critical-background-200 {
                    background: var(--critical-color-200);
                }

                .critical-background-100 {
                    background: var(--critical-color-100);
                }

                .primary-background-100 {
                    background: var(--primary-color-100);
                }
            
                .text-secondary-brand-color {
                    color: var(--secondary-brand-color);
                }            
                
                .vertical-scroll {
                    overflow-y:auto;
                }

            .mainContainer:hover .subContainer {
                display: flex;
            }           
            
            .content-wrap {
                position: relative;
                z-index: 0;
                width: 100%;
                display: block;                    
            }
            
            .success-circle-1,
            .success-circle-2 {
                border: 3px solid var(--primary-light-color);
                border-radius: 50%;
                background: var(--success-label-color);
                width: 10px;
                height: 10px;
            }

            .success-circle-2 {
                background: var(--divider-color);
            }

            .scrollable-content{
                max-height: calc(100vh - 258px);
                overflow-x: auto;
            }

            
            /*Action Buttons hover CSS*/
            .action-button {
                display : none;
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
            }
            
            .action-button-wrapper:hover {
                background: var(--brand-color-50);
            }
            
            .action-button-wrapper:hover .action-button {
                display: flex;
            }
            /*Action Buttons hover CSS*/
            
            .listWrapper {
                max-height: 40vh;
                overflow: auto;
            }

            .listing-1 .listing-items {
                min-height: 53px;
                display: inline-flex;
                width: 100%;
            }
            
            
            .dot-separator {
                font-size: var(--a-font-size-xl);
                color: var(--secondary-dark-color);
                padding: 0 8px;
                position: relative;
                bottom: 2px;
            }

            .image-div-approval-landing {
                background-size: 100% 100%;
                min-height: 212px;
                background-image: var(--dashboard_bg);
            }
            
            .image-div-approval-landing[desktop] {
                min-height: 100px;
            }

            .image-div-payable-landing {
                background-size: 100% 100%;
                min-height: 212px;
                background-image: var(--dashboard_bg);
            }

            .width-panel-1-corporate-mails{
				width:20% ! important;
                min-width:15%;
                overflow: hidden;
                background-image: var( --dashboard_bg_texture);
                background-repeat: no-repeat;
                background-position: bottom;
				background-size: contain;
			}

            .image-div-payable-landing[desktop] {
                min-height: 100px;
            }

            .imagebackGround-payment-collections {
                padding-top: 8px;
                background-size: 100% 100%;
                background-image: var(--dashboard_bg)
            }
            
            app-drop-down.mobile-group-by {
                --position-right: auto;
                --position-left: -52px;

            }
            
            :dir(rtl) app-drop-down.mobile-group-by {
                --position-left: auto;
                --position-right: -52px;

            }

            /* Oe combo style */
            oe-combo {
                --paper-item: {
                    font-size: var(--a-font-size-s);
                }
            }
            /* Oe-combo ie remove close icon */
            oe-combo::-ms-clear{ display:none;}
            
            /* Oe-combo ie remove close icon */
            oe-combo::-ms-clear{ display:none;}
            
            /* Nonwhite space text wrap */
			.wrap-nospace-text{
                -ms-word-break: break-all;
                word-break: break-all;
                word-break: break-word;
                -webkit-hyphens: auto;
                -moz-hyphens: auto;
                hyphens: auto;
            }
            /* styles for corporate cash management starts here*/
            .accounts-div, .account-details {
                background-color: var(--background-secondary);
            }
            .accounts-grid {
                background-color:var(--primary-light-color);
                border-radius: 2.875px;
                margin: 4px 8px !important;
                border: 1px solid var(--border-color);
                box-shadow: 0 2px 3px var(--shadow-color);
                position:relative;
                top:15px;
            }
            .account-details-card {
                padding:0px;
            }
            .account-balance-div {
                background-color:var(--bg-light-blue);
            }
            .balance-div {
                display:flex;  
                position: relative;
                top: 8px;           
            } 
            .balance-div .font-size-m, .balance-div .primary-dark-text-m {
                font-weight:500;
                padding-left: 8px;
            } 
            .available-balance {
               position: relative;
               right: -14px;
            }
            .currency-selector-alignment {
                font-size: 1px; 
            }            
            .account-details .content-align-vertical-center {
                position:relative;
                top:7px;
            } 
            .filter-alignment {
                position:relative;
                top:6px;
            }
			.header-style {
				color: #2E2E2E;
				font-weight: bold;
			}
			.subHeader-style {
				color: #2E2E2E;
				font-family: 'Nunito-Regular';
			}
			.links-style {
				color: #0072D8;
				font-weight: bold;
			}
			oe-i18n-msg {
                font-family:  var(--regular-font) !important;
				--paper-font-common-base: {
					font-family: var(--regular-font) !important;
				}
            }	
            /* styles for corporate cash management ends here*/
        </style>
        
    </template>`;

CorpAppStyles.register('corp-app-styles');