/* ©2018 EdgeVerve Systems Limited (a fully owned Infosys subsidiary),
Bangalore, India. All Rights Reserved.
 */
import { PolymerElement, html } from "@polymer/polymer/polymer-element.js";
import "./mdl-grid-css.js";
/* import '../bower_components/swiper/dist/css/swiper.css'; */
/*  shared styles for all elements and index.html  */

const AppStyles = document.createElement("dom-module");

AppStyles.innerHTML = `<template>
        <style include="mdl-grid-css iron-flex iron-flex-factors iron-flex-alignment">

            /* hidden attribute inside Shadow DOM doesn't work without this style being availabe in each custom element*/
            [hidden] {
                display: none!important;
            }

            /*Removed from mdl-grid.html*/
            /*the root container - no space on mobile except bottom margin, normal grod spacing on tab and desktop*/
            .mdl-grid.mdl-grid--root {
                padding: 8px;
            }

            .mdl-grid--root>.mdl-cell{
                margin: 8px
            }

            .mdl-grid.mdl-grid--center-justified {
                justify-content: center;
            }

            .mdl-cell--auto-width {
                box-sizing: border-box;
                width: auto;
            }
            app-dialog[name="h_Authorization"] {
                --dialog-background-color: {
                    background-color: var(--brand-color);
            };
                --dialog-text-color: {
                color: var(--background-primary);
              }
			}
			.chatbot-body-app-chatbot {
            height: calc(100% - 125px);
            width: 100%;
            padding-top: 0 !important;
            overflow-y: scroll;
            overflow-x: hidden;
            background-image: var(--texture-image);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            }

            .mdl-right-no-spacing {
                padding-right: 0 !important;
                width: calc(100% - 8px) !important;
            }

			.money-bag-fin-amount{
			  background-image: var(--ic_money_bag_normal_images);
			  background-repeat: no-repeat;
			  background-size: 3em;
			  background-position-x: 0.5em;
			  width: 4em;
			  height: 2em;
			  text-align: center;
			  padding-top: 1.5em;
			}

            .mdl-right-no-spacing > .mdl-cell {
                margin-right: 0 !important;
                width: calc(100% - 8px) !important;
            }

            :dir(rtl) .mdl-right-no-spacing {
                padding-left: 0 !important;
                width: calc(100% - 8px) !important;
            }

            :dir(rtl) .mdl-right-no-spacing > .mdl-cell {
                margin-left: 0 !important;
                width: calc(100% - 8px) !important;
            }
                

            @media (max-width: 479px) {
                oe-date {
                    --paper-dialog: {
                        top: 10vh;
                    }
                }
                
                .mdl-right-no-spacing-phone {
                    padding-right: 0 !important;
                    width: calc(100% - 8px) !important;
                }

                .mdl-right-no-spacing-phone > .mdl-cell {
                    margin-right: 0 !important;
                    width: calc(100% - 8px) !important;
                }

                :dir(rtl) .mdl-right-no-spacing-phone {
                    padding-left: 0 !important;
                    width: calc(100% - 8px) !important;
                }

                :dir(rtl) .mdl-right-no-spacing-phone > .mdl-cell {
                    margin-left: 0 !important;
                    width: calc(100% - 8px) !important;
                }

                /*the root container - no space on mobile except bottom margin, normal grod spacing on tab and desktop*/
                .mdl-grid.mdl-grid--root {
                    padding: 0;
                }
                .mdl-grid--root>.mdl-cell {
                    margin: 0 0 8px 0;
                    width: 100%;
                }
                .fade-in{
                    animation: fadeIn ease 4s;
                   -webkit-animation: fadeIn ease 4s;
                   -moz-animation: fadeIn ease 4s;
                   -o-animation: fadeIn ease 4s;
                   -ms-animation: fadeIn ease 4s;
                 }
                 .fade-out{
                   animation: fadeOut 3s .2s ease-in forwards;
                   -webkit-animation: fadeOut 3s .2s ease-in forwards;
                   -moz-animation: fadeOut 3s .2s ease-in forwards;
                   -o-animation: fadeOut 3s .2s ease-in forwards;
                   -ms-animation: fadeOut 3s .2s ease-in forwards;
                 }
                 @keyframes fadeOut{
                   0%   { opacity: 1; }
                   30%  { opacity: 1; }
                   50%  { opacity: 1; }
                   70%  { opacity: 0.2; }
                   100% { opacity: 0.2; }
                 }
                 
                 @-moz-keyframes fadeOut {
                   0%   { opacity: 1; }
                   30%  { opacity: 1; }
                   50%  { opacity: 1; }
                   70%  { opacity: 0.2; }
                   100% { opacity: 0.2; }
                 }
                 
                 @-webkit-keyframes fadeOut {
                   0%   { opacity: 1; }
                   30%  { opacity: 1; }
                   50%  { opacity: 1; }
                   70%  { opacity: 0.2; }
                   100% { opacity: 0.2; }
                 }
                 
                 @-o-keyframes fadeOut {
                   0%   { opacity: 1; }
                   30%  { opacity: 1; }
                   50%  { opacity: 1; }
                   70%  { opacity: 0.2; }
                   100% { opacity: 0.2; }
                 }
                 
                 @-ms-keyframes fadeOut {
                   0%   { opacity: 1; }
                   30%  { opacity: 1; }
                   50%  { opacity: 1; }
                   70%  { opacity: 0.2; }
                   100% { opacity: 0.2; }
                 } 
                 @keyframes fadeIn{
                   0% { opacity:0; }
                   100% { opacity:1;}
                 }
                 @-moz-keyframes fadeIn {
                   0% { opacity:0; }
                   100% { opacity:1;}
                 }
                 
                 @-webkit-keyframes fadeIn {
                   0% { opacity:0; }
                   100% { opacity:1;}
                 }
                 
                 @-o-keyframes fadeIn {
                   0% { opacity:0; }
                   100% { opacity:1;}
                 }
                 
                 @-ms-keyframes fadeIn {
                   0% { opacity:0; }
                   100% { opacity:1;}
                 }
                 .p-fade-in{
                    animation: pfadeIn ease 1s;
                   -webkit-animation: pfadeIn ease 1s;
                   -moz-animation: pfadeIn ease 1s;
                   -o-animation: pfadeIn ease 1s;
                   -ms-animation: pfadeIn ease 1s;
                 }
                 .p-fade-out{
                   animation: pfadeOut 1s .2s ease-in forwards;
                   -webkit-animation: pfadeOut 1s .2s ease-in forwards;
                   -moz-animation: pfadeOut 1s .2s ease-in forwards;
                   -o-animation: pfadeOut 1s .2s ease-in forwards;
                   -ms-animation: pfadeOut 1s .2s ease-in forwards;
                 }
                 @keyframes pfadeOut{
                    0%   { opacity: 1; }
                    100% { opacity: 0; }
                 }
                 
                 @-moz-keyframes pfadeOut {
                    0%   { opacity: 1; }
                    100% { opacity: 0; }
                 }
                 
                 @-webkit-keyframes pfadeOut {
                    0%   { opacity: 1; }
                    100% { opacity: 0; }
                 }
                 
                 @-o-keyframes pfadeOut {
                    0%   { opacity: 1; }
                    100% { opacity: 0; }
                 }
                 
                 @-ms-keyframes pfadeOut {
                    0%   { opacity: 1; }
                    100% { opacity: 0; }
                 } 
                 @keyframes pfadeIn{
                    0%   { opacity: 0; }
                    100% { opacity: 1; }
                 }
                 @-moz-keyframes pfadeIn {
                    0%   { opacity: 0; }
                    100% { opacity: 1; }
                 }
                 
                 @-webkit-keyframes pfadeIn {
                    0%   { opacity: 0; }
                    100% { opacity: 1; }
                 }
                 
                 @-o-keyframes pfadeIn {
                    0%   { opacity: 0; }
                    100% { opacity: 1; }
                 }
                 
                 @-ms-keyframes pfadeIn {
                    0%   { opacity: 0; }
                    100% { opacity: 1; }
                 }
                 .animate-left{ position: relative; animation: animateleft 0.8s; @apply --animate-left-value; }
                 @keyframes animateleft{ from{ right: 0; opacity: 0 } to{ right: -100%; opacity: 1 } }
                 .animate-right{ position: relative; animation: animateright 0.8s;
                 @apply --animate-right-value; } 
                 @keyframes animateright{ from{ right: -100%; opacity: 0 } to{ right: 0; opacity: 1 } }
                 .opacity-screen{
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: var(--card-light-background);
                    display:none;
                    z-index:1;
                  }
                  .opacity-screen.show {
                    display: block;
                  }
                  .success-card {
                    background: none;
                    box-shadow: none;
                  }
                  .payment-details{
                    margin-top: 20px;
                    margin-bottom:4px;
                    padding: 16px 0px;
                    border-top: 1px solid var(--secondary-dark-color);
                    border-bottom: 1px solid var(--secondary-dark-color);
                  }          
                  .download-icon{
                    position: relative;
                    left: 48px;
                    top: 2px;
                  }
                  .share-icon {
                    position: relative;
                    right: 48px;
                    top: 1px;
                  }
            }
            /*Removed from mdl-grid.html*/

            #index_HeaderPanel {
                --paper-scroll-header-panel-header-container: {
                    z-index: 2;
                }
                --paper-scroll-header-panel-container: {
                    -webkit-overflow-scrolling: auto;
                }
            }
            #index_HeaderPanel[phone],#index_HeaderPanel[tablet] {

                --paper-scroll-header-panel-container: {
                    -webkit-overflow-scrolling: touch;
                }
            }

            ::-webkit-scrollbar {
                width: 17px;
            }
            /* Customize Thumb */

            ::-webkit-scrollbar-thumb {
                background: var(--primary-dark-color);
                border-radius: 10px;
                border: 6px solid transparent;
                background-clip: content-box;
                min-height: 35px;
            }
            /* Customize Track */

            ::-webkit-scrollbar-track {
                border: 8px solid transparent;
                background: var(--divider-color);
                background-clip: content-box;
            }
            /*Css for One Gini*/

            .biometric-round-button {
                width: 80px;
                height: 80px;
                border-radius: 50%;
                margin: 0 auto;
            }

            .biometric-round-button > iron-icon {
                --iron-icon-width: 31px;
                --iron-icon-height: 31px;
                --iron-icon-fill-color: var(--primary-light-colo);
            }

            .biometric-iron-icon {
                width: 31px;
                height: 31px;
                border-radius: 50%;
                border: 1;
                margin: 0 auto;
            }

            .biometric-iron-icon-process {
                background: var(--biometric-bg-color);
                width: 10em;
                height: 10em;
                border-radius: 50%;
                border: 1;
                margin: 0 auto;
            }

            .biometric-icon-border {
                border: 1px solid var(--divider-color);
                padding: 4em;
            }

            .biometric-icon-border-process {
                border: 8px solid var(--secondary-light-color);
                padding: 4em;
            }
            /*Css for One Gini*/
            /*Dynamic tabs add, delete*/

            .menu-tabs-3 {
                background-color: transparent;
                color: var(--disabled-dark-color);
                min-width: 165px;
                text-align: center;
                cursor: pointer;
                border: 1px solid var(--outline-divider-color);
                border-right: none;
                border-bottom: none;
                border-radius: 4px 0 0 0;
                min-height: 30px;
                transform: scale(.91, .9) translate(0px, 3px);
                background-image: linear-gradient(-180deg, var(--tab-gradient-start-color) 0%, var(--tab-gradient-end-color) 100%);
                transition: transform 0.1s ease;
                font-family: var(--medium-font);
                /*box-shadow: 0 -1px 0 0 #DCDCDC, 1px 0 0 0 #DCDCDC, -1px 0 0 0 #DCDCDC, inset 0 -1px 0 0 #DCDCDC;*/
				padding:3px 0;
            }

            .menu-tabs-3:after {
                content: "";
                position: absolute;
                border: 1px solid var(--outline-divider-color);
                right: -10px;
                top: -1px;
                bottom: 0;
                width: 16px;
                border-left: none;
                border-bottom: none;
                /*background: wheat;*/
                border-radius: 0 4px 0 0;
                transform: skewx(19deg);
                background-image: linear-gradient(-180deg, var(--tab-gradient-start-color) 0%, var(--tab-gradient-end-color) 100%);
                /*box-shadow: 0 -1px 0 0 #DCDCDC, 1px 0 0 0 #DCDCDC, -1px 0 0 0 #DCDCDC, inset 0 -1px 0 0 #DCDCDC;*/
				padding:3px 0;
            }

            :dir(rtl) .menu-tabs-3 {
                background-color: transparent;
                color: var(--disabled-dark-color);
                min-width: 165px;
                text-align: center;
                cursor: pointer;
                border: 1px solid var(--outline-divider-color);
                border-left: none;
                border-bottom: none;
                border-radius: 4px 0 4px 0;
                min-height: 30px;
                transform: scale(.91, .9) translate(0px, 3px);
                background-image: linear-gradient(-180deg, var(--tab-gradient-start-color) 0%, var(--tab-gradient-end-color) 100%);
                transition: transform 0.1s ease;
                /*box-shadow: 0 -1px 0 0 #DCDCDC, 1px 0 0 0 #DCDCDC, -1px 0 0 0 #DCDCDC, inset 0 -1px 0 0 #DCDCDC;*/
				padding:3px 0;
            }

            :dir(rtl) .menu-tabs-3:after {
                content: "";
                position: absolute;
                border: 1px solid var(--outline-divider-color);
                left: -10px;
                right: auto;
                top: -1px;
                bottom: 0;
                width: 16px;
                border-right: none;
                border-bottom: none;
                /*background: wheat;*/
                border-radius: 4px 0 0 0;
                transform: skewx(-19deg);
                background-image: linear-gradient(-180deg, var(--tab-gradient-start-color) 0%, var(--tab-gradient-end-color) 100%);
                /*box-shadow: 0 -1px 0 0 #DCDCDC, 1px 0 0 0 #DCDCDC, -1px 0 0 0 #DCDCDC, inset 0 -1px 0 0 #DCDCDC;*/
				padding:3px 0;
            }

            .menu-tabs-3-active,
            :dir(rtl) .menu-tabs-3-active{
                bottom: 0;
                background: var(--brand-color-100);
                font-family: var(--medium-font);
                z-index: 1;
                color: var(--primary-light-color);
            }

            .menu-tabs-3-active.menu-tabs-3:after,
            :dir(rtl) .menu-tabs-3-active.menu-tabs-3:after{
                background: var(--brand-color-100);
            }

            .menu-tabs-3 .iron-icon-style-s,
            :dir(rtl) .menu-tabs-3 .iron-icon-style-s{
                color: var(--disabled-text-color);
                opacity: 70%;
            }

            .menu-tabs-3-active .iron-icon-style-s,
            :dir(rtl) .menu-tabs-3-active .iron-icon-style-s{
                color: var(--primary-light-color);
                opacity: 70%;
            }

            /*removing focus outline for tab heading*/
            .menu-tabs-3 .style-tab:focus {
                outline: none;
            }

            /*.tab-container{
                min-width: 96px;
                margin-bottom: 0px !important;
            }*/
            /*Dynamic tabs add, delete*/

            paper-tooltip {
                --paper-tooltip: {
                    font-size: var(--tooltip-font-size);
                }
                --paper-tooltip-opacity: 1;
                --paper-tooltip-text-color: var(--secondary-dark-color);
                --paper-tooltip-background: var(--primary-light-color);
                box-shadow: 0 2px 4px 0 var(--secondary-dark-color);
                margin-top:9px; 
            }
            /*Paper radio button, checkbox and Switch css*/

            paper-radio-button.primary-radio {
                font-size: var(--a-font-size-s);
                --paper-radio-button-size: 22px;
                --paper-radio-button-unchecked-color: var(--divider-color);
                --paper-radio-button-checked-color: #0072D8;
                --paper-radio-button-label-color: var(--secondary-dark-color);
            }

            paper-checkbox.primary-checkbox {
                --paper-checkbox-unchecked-color: #99C1E7;
                --paper-checkbox-checked-color: #0072D8;
                --paper-checkbox-checkmark-color: var(--primary-light-color);
                --paper-checkbox-checked-ink-color: var(--brand-color);
                --paper-checkbox-label-color: var(--secondary-dark-color);
            }

            paper-checkbox {
                font-size: var(--a-font-size-s);
            }

            paper-checkbox.checkbox-light-style[desktop] {
                --paper-checkbox-unchecked-color: var(--primary-light-color);
                --paper-checkbox-checked-color: var(--primary-light-color);
                --paper-checkbox-checkmark-color: var(--brand-color);
                --paper-checkbox-checked-ink-color: var(--primary-light-color);
                --paper-checkbox-unchecked-color: var(--primary-light-color);
            }

            paper-toggle-button {
                opacity: 1 !important;

                --paper-toggle-button-unchecked-bar: {
                    background-color: var(--divider-color);
                    height: 20px;
                    margin-top: 0px;
                    border-radius: 11px;
                    border: solid 1px var(--divider-color);

                }
                --paper-toggle-button-checked-bar: {
                    opacity:1.0;
                    background-color: var(--brand-color);
                    border: solid 1px var(--brand-color);
                }
                --paper-toggle-button-unchecked-button: {
                    top: 0;
                }
                --paper-toggle-button-checked-button: {
                    background-color: var(--primary-light-color);
                }
                --paper-toggle-button-unchecked-button: focus {
                   opacity:0.5;
                   outline:none;
                }
                --paper-toggle-button-checked-button: focus{
                    opacity:0.5;
                    outline:none;

                }
            }
            /*Paper radio button, checkbox and Switch css*/
            /*Input text box mixin*/

            oe-input,
            oe-date oe-input,
            oe-date,
            gold-email-input,
            oe-decimal, oe-textarea, oe-combo, gold-phone-input {
                --paper-input-container-underline: {
                    border-bottom-color: var(--ripple-color);
                }
                --paper-input-container-label: {
                    color: var(--secondary-dark-color);
					font-family: Nunito-Regular !important;
                }
				--paper-input-container-label-floating: {  			
					font-family: Nunito-Regular !important;
                }
                font-family: 'Nunito-Regular' !important;
                --paper-input-container-input-color: var(--primary-dark-color);
                --paper-input-container-invalid-color: var(--secondary-color-but);
                --paper-input-container-input: {
                    text-align: initial;
                    line-height: 26px;
					font-family: Nunito-Regular !important;
                }
                --paper-input-container-label-focus: {
                    color: var(--secondary-dark-color);
                }
                --paper-input-error: {
                    margin-top: 2px;
                }
                /*--paper-input-container-underline-focus: {
                    display: none;
                }*/

                --paper-dropdown-menu-icon: {
                    color: var(--secondary-dark-color);
                }

            }

            /*To remove required start asterisk*/
            oe-input, oe-combo, oe-date, oe-decimal, oe-radio-group {
                --oe-required-mixin: {
                    display:none;
            }
            }

            /* code to remove asterisk symbol from required inputs */

			/* To control the height and spacing between records of oe-combo */
			oe-combo {
				--paper-item-min-height: 35px;
				--paper-material:{
					max-height: 300px !important;
				}
				--paper-item-focused: {
					background-color: #ccc;
                    outline-width:2px;
                    outline-style:solid;
                    outline-color:#0075da !important;
                    outline-offset:-1px;
				}
			}


            oe-decimal {
                --paper-input-container-input:{
                    font-size: var(--a-font-size-l);
                    line-height: 26px;
                }
            }
            .currency-prefix {
                font-size: var(--a-font-size-xl);
            }

            oe-input.text-align-right {
                --paper-input-container-label: {
                    text-align: right;
                }
                --paper-input-container-input: {
                    text-align: right;
                }
            }

            oe-input.no-border {
                --paper-input-container-underline: {
                    border-bottom: none;
                }
            }
            /*For hiding side panel in date picker in mobile*/
            @media only screen and (max-width: 768px) {
                oe-date {
                  --oe-datepicker-sidepanel:{
                    display:none;
                  }
                  --oe-datepicker-mainpanel:{
                    border-width: 1px 1px 1px 1px;
                  }
                }
              }
              
            /*Input text box mixin*/

            gold-cc-input,
            gold-email-input {
                --paper-input-container-underline: {
                    border-bottom-color: var(--divider-color);
                }
                --paper-input-container-label: {
                    color: var(--secondary-dark-color);
                }
                --paper-input-container-underline-focus: {
                    display: none;
                }
            }

            gold-cc-expiration-input {
                --paper-input-container-underline: {
                    border-bottom-color: var(--divider-color);
                }
                --paper-input-container-label: {
                    color: var(--secondary-dark-color);
                }
                --paper-input-container-underline-focus: {
                    display: none;
                }
            }
            /* Updated Style for checkbox */
            .newCheckbox {
                --paper-checkbox-unchecked-color: var(--field-unchecked-color);
                --paper-checkbox-checked-ink-size:48px;
            }

            /*Drop down text box mixin*/
            /*Sticky content CSS*/

            .display-none {
                display: none;
            }

            .sticky {
                position: fixed;
                top: 56px;
                width: 100%;
                z-index: 1;
                display: block;
            }
            /*Sticky content CSS*/
            /*Style for oe-info*/
            oe-info.primary-dark-color {
                --oe-info-label: {
                    color: var(--primary-dark-color);
                }
                --oe-info-value: {
                    color: var(--primary-dark-color);
                }
            }

            oe-info.secondary-dark-color-s {
                --oe-info-label: {
                    font-size: var(--a-font-size-s);
                    color: var(--secondary-dark-color);
                }
                --oe-info-value: {
                    font-size: var(--a-font-size-s);
                    color: var(--secondary-dark-color);
                }
            }

            oe-info.secondary-light-color {
                --oe-info-label: {
                    color: var(--secondary-light-color);
                }
                --oe-info-value: {
                    color: var(--secondary-light-color);
                }
            }

            oe-info {
                font-size: inherit;
                --oe-info-label: {
                    padding: 0;
                    font-size: var(--a-font-size-m);
                    color: var(--primary-dark-color);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                --oe-info-value: {
                    padding: 0;
                    font-size: var(--a-font-size-s);
                    color: var(--disabled-dark-color);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            oe-info.display-date {
                --oe-info-value: {
                    font-size: var(--a-font-size-m);
                }
            }
            /*For hiding side panel in date picker in mobile*/
            @media only screen and (max-width: 768px) {
                oe-date{
                  --oe-datepicker-sidepanel:{
                    display:none;
                  }
                  --oe-datepicker-mainpanel:{
                    border-width: 1px 1px 1px 1px;
                  }
                }
              }

            /*Style for oe-info*/
            /*Fab button with image css*/

            .fab-button-image paper-fab {
                --paper-fab-disabled-background: var(--background-secondary);
                --paper-fab-disabled-text: var(--primary-light-color);
                --paper-fab-iron-icon: {
                    color: var(--primary-light-color);
                }
            }

            .fab-button-l {
                width: 67px;
                height: 67px;
            }
            /*Fab button with image css*/
            /*Shadow elevation*/

            .elevation-1 {
                box-shadow: 0 -3px 11px 0 var(--secondary-dark-color);
            }

            .elevation-2 {
                box-shadow: 0 2px 4px 0 var(--elevation-color);
            }

            .elevation-2-inset {
                box-shadow: 0 2px 4px 0 var(--elevation-color) inset;
            }

            .elevation-3 {
                box-shadow: 0 2px 21px 0 var(--elevation-color);
            }

            .elevation-4 {
                box-shadow: 0px 20px 40px 10px var(--elevation-color) ;
            }

            .elevation-5 {
                box-shadow: 0px 3px 4px 0px var(--disabled-dark-color) !important;
            }

            .elevation-inset-card{
                box-shadow: 0 12px 4px -10px var(--elevation-color) inset, 0 -12px 4px -10px var(--elevation-color) inset;
            }

            /*Shadow elevation*/
            /*input text/label css for desktop view*/

            .light-text-color-desktop[desktop], .light-input-style {
                /*Added for color on first page*/
                --paper-input-container-shared-input-style_-_color:var(--primary-light-color);
                /*Space between Fields*/
                --paper-input-container_-_padding-bottom :10px !important;
                --paper-input-container-disabled: {
                    opacity: .8;
                }
                --paper-input-container-underline: {
                    border-bottom-color: var(--primary-light-color);
                }
                --paper-input-container-underline-disabled: {
                    border-bottom-color: var(--primary-light-color);
                }
                --paper-dropdown-menu-icon: {
                    color: var(--primary-light-color);
                }
                --paper-input-container-input: {
                    color: var(--primary-light-color);
                    line-height: 26px;
                }
                --paper-input-container-label: {
                    color: var(--primary-light-color);
                }
                --paper-input-container: {
                    color: var(--primary-light-color);
                }
                --paper-input-container-color: var(--secondary-light-color);
                --paper-input-container-label-focus: {
                    color: var(--primary-light-color);
                }
            }
            /*input text/label css for desktop view*/

            paper-dropdown-menu {
                --paper-input-container-underline: {
                    border-bottom-color: var(--ripple-color);
                }
                --paper-input-container-label: {
                    color: var(--secondary-dark-color);
                }
                --paper-input-container-input: {
                    text-align: initial;
                    line-height: 26px;
                    padding-bottom: 10px;
                }
                --paper-input-container-input-color: var(--primary-dark-color);
                --paper-input-container-label-focus: {
                    color: var(--secondary-dark-color);
                }
            }
            /*Style for paper-tabs*/

            paper-tab {
                --paper-tab: {
                    color: #2E2E2E;
					font-family: Nunito-Regular;
                    font-size: var(--a-font-size-m);
                }
                --paper-tab-ink: var(--brand-color);

            }
            paper-tabs {
                --paper-tabs-selection-bar-color: #FDB913;
				--paper-tabs-selection-bar: {
					border: 2px solid #FDB913;
				}
                font-size: inherit;
                transition: all 0.4s;

            }
            paper-tab:hover{
                color: #2E2E2E;
                opacity:0.6;
            }

            paper-tabs paper-tab.iron-selected {
                color: #2E2E2E;
				font-weight: bold;
            }
            paper-tabs paper-tab.iron-selected:hover{
                color: #2E2E2E;
                opacity:0.6;                 
            }
            @media (max-width:1024px){
                paper-tab:hover{
                    opacity:1 !important;
                }
            }
            /*currency formatting css*/

            .currency-decimal,
            .currency-symbol {
                font-size: .78em;
            }
            /*currency formatting css*/

            .currency-decimal,
            .currency-symbol {
                font-size: .78em;
            }
            /*currency formatting css*/
            /*Style for overlay of profile page in desktop/tablet mode*/

            .profile-overlay-tab-desktop {
                background-color: var(--disabled-dark-color);
                color: var(--primary-dark-color);
                text-align: left;
                position: fixed;
                width: 100%;
                height: 100vh;
                top: 64px;
                left: 0px;
                z-index: 1;
            }

            :dir(rtl) .profile-overlay-tab-desktop {
                background-color: var(--disabled-dark-color);
                color: var(--primary-dark-color);
                text-align: right;
                position: fixed;
                width: 100%;
                height: 100vh;
                top: 64px;
                right: 0px;
                left: auto;
                z-index: 1;
            }

            .profile-content {
                height: Calc(100vh - 64px);
                overflow: auto;
            }
            /*Style for overlay of profile page in desktop/tablet mode*/
            /*Registration/onboarding skewed banner*/

            div.banner-skew {
                position: absolute;
                top: 6.6vw;
                width: 100%;
                right: 0;
                min-height: 440px;
                background: var(--brand-color);
                transform: skewy(8deg);
                overflow:hidden;
            }

            div.banner-skew:before {
                content: "";
                position: absolute;
                width: 100%;
                top:7vw;
                min-height:440px;
                transform: skewy(-8deg);
                background-image: var( --dashboard_bg_texture);
                background-repeat: no-repeat;
                filter: brightness(1.5);
                background-size: 200%;
                background-position: bottom;
            }

            :dir(rtl) div.banner-skew {
                position: absolute;
                top: 6.6vw;
                width: 100%;
                left: 0;
                right: auto;
                min-height: 440px;
                transform: skewy(-8deg);
            }

            /*Registration/onboarding skewed banner*/

            .page-left-image {
                background: var( --background-image);
                background-size: cover;
                background-repeat: no-repeat;
                background-position: bottom;
            }

            paper-card,
            oe-input,
            sb-phone,
            oe-date,
            gold-cc-input,
            gold-email-input {
                width: 100%;
            }
            /*Paper card background light*/

            paper-card {
                background: var(--primary-light-color);
            }
            /*Paper button background light*/
            paper-button {
                --paper-button: {
                    font-size: var(--a-button-font-size);
                }
            }

            paper-button i18n-msg {
                color: var(--flat-button-color);
                font-family: var(--medium-font);
            }


            paper-button i18n-msg {
                color: var(--flat-button-color);
                font-family: var(--medium-font);
            }
            paper-button : focus{
                opacity :0.5;
            }

            paper-toolbar.header-style {
                --paper-toolbar-background: var(--primary-light-color);
                --paper-toolbar-color: var(--primary-dark-color);
                --paper-toolbar: {
                    box-shadow: 0px 0px 0px;
                }
            }

            paper-toolbar {
                --paper-toolbar-background: var(--primary-light-color);
                --paper-toolbar-color: var(--primary-dark-color);
                --paper-toolbar: {
                    box-shadow: 0px 0px 0px;
                }
                ;
                --paper-toolbar-content: {
                    padding: 0px !important;
                }
            }

            paper-toolbar[phone] {
                padding-top: 26px !important;
                height: 76px !important;
            }

            paper-item {
                --paper-item: {
                    font-size: unset;
                }
            }

            paper-item {
                --paper-item-focused-before: {
                    background: transparent;
                }

            }

            .listing-1 .listing-items:nth-child(even) {
                background: var(--brand-color-50);
            }

            .listing-2 .listing-items:nth-child(odd) {
                background: var(--brand-color-50);
            }


            /*Phone number having Country code*/
            /*--paper-input-container-underline: {
                        border-bottom-color: var(--divider-color);
                    } */
            /*Phone number having Country code*/
            paper-icon-item {
                --paper-item-focused-before: {
                    background: transparent;
                }
            }

            paper-card.round-icon-list-wrapper {
                box-shadow: var(--shadow-elevation-1);
                border-radius: 6px;
            }

            paper-icon-item.round-content-list {
                --paper-item-icon-width: 80px;
            }
            /*to fix the footer at bottom having button*/

            .fixed-footer {
                position: absolute;
                bottom: calc(0vh - -16px);
                left: 0;
                right: 0;
            }

            :dir(rtl) .fixed-footer {
                position: absolute;
                bottom: calc(0vh - -16px);
                right: 0;
                left: 0;
            }

            .fixed-footer-container {
                padding-bottom: 56px;
            }

            .display-flex-wrap {
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                flex-wrap: wrap;
            }

            .content-wrapper[phone] {
                min-height: calc(100vh - 56px);
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                flex-wrap: wrap;
            }

        .container {
                min-height: calc(100vh - 56px);
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                flex-wrap: wrap;
            }

            .container[desktop] {
                min-height: calc(100vh - 136px);
            }

            .justify-content-space-bwn {
                -webkit-justify-content: space-between;
                justify-content: space-between;
            }

            .page-container-min-height {
                min-height: calc(100vh - 64px);
            }

            .page-container-min-height[phone] {
                min-height: calc(100vh - 76px);
            }
            /*flex align items end*/

            .align-end {
                align-self: flex-end;
            }
            /*flex align items start*/

            .align-start {
                align-self: flex-start;
                align-items: start;
            }
            /*to fix the footer at bottom having button*/

            .body {
                font-size: var(--body-font-size);
                font-family: 'Nunito-Regular' !important;
                margin: 0;
                position: relative;
                background-color: var(--primary-light-color);
                height: initial;
            }

            html,
            body {
                height: 100%;
                margin: 0;
            }

            paper-scroll-header-panel {
                height: 100%;
            }
            /*.header-style {
                background: var(--primary-light-color);
                box-shadow: none;
            }*/

            .back-button paper-icon-button {
                padding: 0;
                width: 24px;
                height: 24px;
            }

            .page-header-text-wrapper {
                font-size: var(--a-font-size-m);
                letter-spacing: -0.41px;
                font-weight: var(--font-weight-bold);
                text-align: center;
                padding: 4px 0 0 0;
            }

            .banner-icon-wrapper {
                background: var(--background-secondary);
                width: 154px;
                height: 154px;
                border-radius: 50%;
                margin: 10vh auto;
            }

            .banner-icon-text {
                color: var(--primary-dark-color);
                font-size: var(--a-font-size-m);
                width: 42%;
                margin-top: -20px;
            }

            .unfocused-line.paper-input-container {
                background-color: var(--divider-color);
            }
            /*Registration image Verification*/
            /*.banner-image-verify {
                background:  var(--Img_sprite_graphics) no-repeat;
                width: 154px;
                height: 154px;
                margin: 4vh auto;
                background-size: cover;
            }*/

            .banner-image-verify {
                width: 154px;
                height: 154px;
                margin: 4vh auto;
            }

            .page-left-image-direct-banking {
                background: var(--background_Image_sd);
                background-size: cover;
                background-repeat: no-repeat;
                background-position: bottom;
            }

            .mainpage-ev-login-admin {
                background-image: var(--login_background);
                height: 100%;
                width: 100%;
            }

            .image-div-profile-details{
				background-size:100% 100%;
				background-image: var(--dashboard_bg);
			}

            .image-div-corporate-profile-main {
                background-size: 100% 100%;
                background-image: var(--dashboard_bg);
            }

            .banner-image-savings-reg {
                background: var(--Saving_account_graphics_3) no-repeat;
                width: 154px;
                height: 154px;
                background-size: cover;
                background-position: 0 0;
            }

            .banner-image-savings {
                background:var(--Img_sprite_graphics) no-repeat;
                width: 130px;
                height: 130px;
                background-size: cover;
                background-position: 0 -130px;
            }
            /*Registration image Verification*/

            .profile-pic-banner {
                --iron-icon-width: 100%;
                --iron-icon-height: 100%;
            }

            .banner-profile-image-1 {
                border: 2px solid var(--primary-light-color);
                width: 174px;
                height: 174px;
                border-radius: 50%;
                margin-top: -87px;
            }

            .round-button {
                background: var(--brand-color);
                width: 80px;
                height: 80px;
                border-radius: 50%;
                margin: 0 auto;
            }

            .round-button-selected {
                background: var(--primary-light-color);
                width: 80px;
                height: 80px;
                border-radius: 50%;
                margin: 0 auto;
            }
            /*Round button Style with icon*/

            .round-button > iron-icon {
                --iron-icon-width: 31px;
                --iron-icon-height: 31px;
                --iron-icon-fill-color: var(--primary-light-color);
            }

            .round-button-selected > iron-icon {
                --iron-icon-width: 31px;
                --iron-icon-height: 31px;
                --iron-icon-fill-color: var(--brand-color);
            }

            .round-button > iron-icon[icon="Ico_aadhar_36"] {
                --iron-icon-width: 49px;
                --iron-icon-height: 44px;
            }

            .split-eqaully {
                flex: 1;
            }
            /*Close button to pop up*/

            .pop-over-close-button {
                position: relative;
                top: 4px;
                right: 4px;
            }

            :dir(rtl) .pop-over-close-button {
                position: relative;
                top: 4px;
                left: 4px;
                right: auto;
            }

            /*OTP verify input style*/

            *[dir=ltr] .otp-verify-wrapper oe-input {
                width: 30px;
                text-align: center;
                margin-right: 8px;
                --paper-input-container-input: {
                    text-align: center;
                }
            }
            *[dir=rtl] .otp-verify-wrapper oe-input {
                width: 30px;
                text-align: center;
                margin-left: 8px;
                --paper-input-container-input: {
                    text-align: center;
                }
            }

            /*OTP verify input style*/
            /*icon styling*/
            /*Success icon styling*/

            .success-icon-m {
                --iron-icon-width: 48px;
                --iron-icon-height: 48px;
                --iron-icon-fill-color: var(--success-label-color);
            }

            .iron-icon-style-m {
                width: 32px;
                height: 32px;
                min-width: 32px;
            }

            .iron-icon-style-l {
                width: 42px;
                height: 42px;
                min-width: 42px;
            }

            .iron-icon-style-xxl {
                width: 72px;
                height: 72px;
                min-width: 72px;
            }
            
            .iron-icon-style-xl {
                width: 52px;
                height: 52px;
                min-width: 52px;
            }

            .iron-icon-style-s {
                width: 22px;
                height: 22px;
                min-width: 22px;
            }
			.iron-icon-color{
				color: var(--disabled-dark-color);
			}
			iron-icon.circle-unchecked{
				color: var(--divider-color);
			}
			iron-icon.circle-checked{
				color: var(--brand-color);
			}
            /*icon styling*/
            /*Style for paper-icon with left and right padding zero. This is for twitter, facebook, google plus icons, so that their alignment will not break in small screen devices like Galaxy S5.*/

            *[dir=ltr] paper-icon-button.padding-lr-zero {
                --paper-icon-button: {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                    width: 24;
                }
            }
            *[dir=rtl] paper-icon-button.padding-lr-zero {
                --paper-icon-button: {
                    padding-right: 0 !important;
                    padding-left: 0 !important;
                    width: 24;
                }
            }
            paper-icon-button.paper-icon-button-no-ink {
            --paper-icon-button-ink-color:transparent;
            }

            /*frequently used styles*/
            /*white Background CSS*/

            .light-background {
                background: var(--primary-light-color);
            }
            /*Brand Background CSS*/

            .brand-background-color {
                background: var(--brand-color);
            }

            .secondary-brand-background {
                background: var(--secondary-brand-color);
            }
            /*Gray background CSS*/

            .background-primary {
                background: var(--background-primary);
            }

            .background-secondary {
                background: var(--background-secondary);
            }

            .background-transparent[desktop] {
                background: transparent;
            }

            .secondary-dark-background {
                background: var(--secondary-dark-color);
            }

            .row-flex-container {
                -ms-box-orient: horizontal;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -moz-flex;
                display: -webkit-flex;
                display: flex;
                -webkit-justify-content: space-around;
                justify-content: space-around;
                -webkit-flex-flow: row wrap;
                flex-flow: row wrap;
                -webkit-align-items: stretch;
                align-items: stretch;
            }

            .content-align-center {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .content-align-vertical-center {
                display: flex;
                align-items: center;
            }

            .content-align-horizontal-end {
                display: flex;
                justify-content: flex-end;
            }

            .column-flex-container {
                -ms-box-orient: vertical;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -moz-flex;
                display: -webkit-flex;
                display: flex;
                -webkit-justify-content: space-around;
                justify-content: space-around;
                -webkit-flex-flow: column wrap;
                flex-flow: column wrap;
                -webkit-align-items: stretch;
                align-items: stretch;
            }
            /*Align text*/

            .text-align-right {
                text-align: right;
            }

            .text-align-left {
                text-align: left;
            }

            :dir(rtl) .text-align-right {
                text-align: left;
            }

            :dir(rtl) .text-align-left {
                text-align: right;
            }

            .text-align-center {
                text-align: center;
            }

			.text-align-justify {
				text-align: justify;
			}

            .text-transform-uppercase {
                text-transform: none;
            }

            .text-transform-lowercase {
                text-transform: lowercase;
            }

            .text-transform-capitalize {
                text-transform: capitalize;
            }

            .text-overflow-ellipsis {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .full-width {
                width: 100% !important;
            }

            .full-height {
                height: 100%;
            }

            .full-height-desktop[desktop] {
                min-height: 100vh;
            }

            .height-fill-available {
                height: -webkit-fill-available;
            }

            .height-auto  {
                height: auto;
            }

            .min-height-auto {
                min-height: auto;
            }

            .half-width {
                width: 50%;
            }

            .margin-zero {
                margin: 0 !important;
            }

            :dir(ltr) .margin-r-zero {
                margin-right: 0 !important;
            }

            :dir(ltr) .margin-l-zero {
                margin-left: 0 !important;
            }

            :dir(rtl) .margin-r-zero {
                margin-left: 0 !important;
            }

            :dir(rtl) .margin-l-zero {
                margin-right: 0 !important;
            }

            .margin-t-zero {
                margin-top: 0 !important;
            }

            .margin-b-zero {
                margin-bottom: 0 !important;
            }

            .padding-zero {
                padding: 0 !important;
            }

            .padding-zero-phone[phone] {
                padding: 0;
            }

            .padding-b-zero {
                padding-bottom: 0 !important;
            }

            :dir(ltr) .padding-r-zero {
                padding-right: 0 !important;
            }

            :dir(ltr) .padding-l-zero {
                padding-left: 0 !important;
            }

            .padding-lr-zero {
                padding-right: 0 !important;
                padding-left: 0 !important;
            }

            :dir(rtl) .padding-r-zero {
                padding-left: 0 !important;
            }

            :dir(rtl) .padding-l-zero {
                padding-right: 0 !important;
            }

            .padding-t-zero {
                padding-top: 1px !important;
            }

            .font-size-inherit {
                font-size: inherit !important;
            }

            .font-size-xxs {
                font-size: var(--a-font-size-xxs) !important;
            }

            .font-size-xs {
                font-size: var(--a-font-size-xs) !important;
            }

            .font-size-s {
                font-size: var(--a-font-size-s) !important;
            }

            .font-size-m {
                font-size: var(--a-font-size-m) !important;
            }

            .font-size-l {
                font-size: var(--a-font-size-l) !important;
            }

            .font-size-xl {
                font-size: var(--a-font-size-xl) !important;
            }

            .font-size-xxl {
                font-size: var(--a-font-size-xxl) !important;
            }
            /*Roboto Font families*/

            .font-weight-light {
                font-family: var(--light-font) !important;
            }

            .font-weight-bold {
                font-family: var(--bold-font) !important;
            }

            .font-regular {
                font-family: var(--regular-font) !important;
            }

            .font-medium {
                font-family: var(--medium-font) !important;
            }

            .font-thin {
                font-family: var(--thin-font) !important;
            }

            .font-italic {
                font-style: italic !important;
            }
            /*Roboto Font families*/

            .overflow-hidden {
                overflow: hidden;
            }

            .overflow-x-hidden {
                overflow-x: hidden;
            }

            .overflow-y-hidden {
                overflow-y: hidden;
            }

            .overflow-visible {
                overflow: visible;
            }

            .line-height-medium {
                line-height: 18px;
            }

            .line-height-nomal {
                line-height: normal;
            }
            /*Border style */

            .border-1px {
                border: 1px solid var(--divider-color);
            }

            .border-b-1px {
                border-bottom: 1px solid var(--divider-color);
            }

            .border-t-1px {
                border-top: 1px solid var(--divider-color);
            }

            .border-l-1px {
                border-left: 1px solid var(--divider-color);
            }

            .border-r-1px {
                border-right: 1px solid var(--divider-color);
            }

            :dir(rtl) .border-l-1px {
                border-right: 1px solid var(--divider-color);
            }

            :dir(rtl) .border-r-1px {
                border-left: 1px solid var(--divider-color);
            }

            .border-light-1px {
                border: 1px solid var(--primary-light-color);
            }

            .border-round-8px {
                border-radius: 8px;
            }

            .border-round-16px {
                border-radius: 16px;
            }

            .border-round-24px {
                border-radius: 24px;
            }

			.border-round-tl-tr-24px {
                border-radius: 24px 24px 0 0;
            }

			.border-round-bl-br-24px {
                border-radius: 0 0 24px 24px;
            }

            .border-round-4px {
                border-radius: 4px;
            }

            .border-radius-tl-tr-12px {
                border-radius: 12px 12px 0 0;
            }

            .border-radius-bl-br-12px {
                border-radius: 0 0 12px 12px;
            }

            .border-radius-0 {
                border-radius: 0;
            }

            .border-b-dotted {
                border-bottom: 2px dotted var(--divider-color);
            }

            .border-b-dashed {
                border-bottom: 2px dashed var(--divider-color);
            }

            .active-border-l {
                border-left: 4px solid var(--brand-color);
            }

            :dir(rtl) .active-border-l {
                border-right: 4px solid var(--brand-color);
            }
            .active-border-l-8px {
                border-left: 8px solid var(--brand-color);
            }

            :dir(rtl) .active-border-l-8px {
                border-right: 8px solid var(--brand-color);
            }

            /*List active element having border*/
            .list-active-item {
                border-left: 4px solid var(--brand-color);
                position: absolute;
                left: 0;
                width: 4px;
                height: 100%;
                top: 0;
            }

            :dir(rtl) .list-active-item {
                border-right: 4px solid var(--brand-color);
                position: absolute;
                right: 0;
                left: auto;
                width: 4px;
                height: 100%;
                top: 0;
            }
            /*Border style */
            /*Webcomponent template CSS*/
            /*Color for button*/
            paper-button.primary-button {
                /*Primary button class*/
                background-color: #0072D8;
                font-family: var(--medium-font);
                font-size: var(--a-button-font-size);
				border-radius: 8px;
                box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.2), 0 6px 12px 0 rgba(0, 0, 0, 0.19);
                transition: all 0.4s;
                --paper-button: {
                    color: #FFFFFF;
                    min-width: 62px;
                    height: 36px;
                    font-size: var(--a-button-font-size);
                    letter-spacing: 0;
                    line-height: 30px;
                    text-shadow: 0 1px 2px rgba(--button-text-shadow-color);
                    padding:8px 16px;
					font-weight: bold;
                }

            }
            paper-button.primary-button:hover {
                background: #004EA8;
                opacity:0.7;
              }

            paper-button.secondary-button {
                /*Secondary button class*/
                background-color: var(--primary-light-color);
				border: 1px solid var( --primary-color-but);
                font-family: var(--medium-font);
                border-radius: 8px;
                border:2px solid #0072D8;
                box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.2), 0 6px 12px 0 rgba(0, 0, 0, 0.19);
                transition: all 0.4s;
                --paper-button: {
                    color: #0072D8 !important;
                    min-width: 62px;
                    height: 36px;
                    font-size: var(--a-button-font-size);
                    letter-spacing: 0;
                    line-height: 30px;
                    text-shadow: 0 1px 2px rgba(--button-text-shadow-color);
                    padding:8px 16px;
                    margin-right:10px;
                }
            }
            paper-button.secondary-button:hover {
                background: #E5F5FF;
                opacity:0.6;
              }

            paper-button.flat-button {
                /*flat primary button class*/
                font-family: var(--medium-font);
                --paper-button: {
                    color: var(--primary-dark-color);
                    min-width: 140px;
                    height: 40px;
                    font-size: var(--a-button-font-size);
                    letter-spacing: 0;
                    line-height: 30px;
                    text-shadow: 0 1px 2px rgba(--button-text-shadow-color);
                    color: var(--brand-color);
                }
            }
            paper-button.flat-button:hover{
                opacity:0.7;
            }
            paper-button.supporting-button {
                /*Supporting Action button class*/
                background-color: var(--primary-light-color);
                --paper-button: {
                    color: var(--primary-dark-color);
                    min-width: 140px;
                    height: 40px;
                    font-size: var(--a-button-font-size);
                    letter-spacing: 0;
                    line-height: 30px;
                    text-shadow: 0 1px 2px rgba(--button-text-shadow-color);
                    color: var(--brand-color);
                    text-transform: inherit;
                    padding: 0;
                    margin: 0;
                }
            }
            paper-button.supporting-button:hover{
                opacity:0.7;
            }
            /*Color for primry button*/
            /*Icons critical style(Triangle icon)*/
            app-dialog.header-brand-color {
                --dialog-background-color: {
                                background-color: #0072D8;
                }
                --dialog-text-color: {
                                color: var(--background-primary);
                }
            }

            .critical-icon {
                color: var(--critical-label-color);
                width: 36px;
                height: 31px;
            }
            /*Text brand color*/

            .text-brand-color {
                color: var(--brand-color) !important;
            }
            /*Banner brand color*/

            .text-decoration-none {
                text-decoration: none;
            }

            .banner-brand {
                background: var(--brand-color);
                min-height: 140px;
                background-image: var( --dashboard_bg_texture)
                background-repeat-y: no-repeat;
                background-position: center;
            }

            .light-banner {
                background: var(--primary-light-color);
                min-height: 240px;
            }
            /*Style for text in primary-light-color*/

            .primary-light-color {
                color: var(--primary-light-color);
            }
            /*Title Style*/

            .title-dark {
                color: var(--primary-dark-color);
                font-family: var(--medium-font);
                font-size: var(--a-font-size-l);
            }

            .title-light {
                color: var(--primary-light-color);
                font-family: var(--medium-font);
                font-size: var(--a-font-size-l);
            }
            /*Header Style*/

            .header-dark {
                color: var(--primary-dark-color);
                font-family: var(--medium-font);
                font-size: var(--a-font-size-m);
                padding: 16px 16px 0 16px;
                display: flex;
            }

            .header-light {
                color: var(--primary-light-color);
                font-family: var(--medium-font);
                font-size: var(--a-font-size-m);
                padding: 16px 16px 0 16px;
            }
            /*Primary text css*/

            .primary-dark-text-xs {
                font-size: var(--a-font-size-xs);
                color: var(--primary-dark-color);
            }

            .primary-dark-text-s {
                font-size: var(--a-font-size-s);
                color: var(--primary-dark-color);
            }

            .primary-dark-text-m {
                font-size: var(--a-font-size-m);
                color: var(--primary-dark-color);
            }

            .primary-dark-text-l {
                font-size: var(--a-font-size-l);
                color: var(--primary-dark-color);
            }

            .primary-dark-text-xl {
                font-size: var(--a-font-size-xl);
                color: var(--primary-dark-color);
            }

            .primary-dark-text-xxl {
                font-size: var(--a-font-size-xxl);
                color: var(--primary-dark-color);
                font-family: var(--thin-font);
            }

            .primary-dark-text-xxxl {
                font-size: var(--a-font-size-xxxl);
                color: var(--primary-dark-color);
            }

            .primary-light-text-xs {
                font-size: var(--a-font-size-xs);
                color: var(--primary-light-color);
            }

            .primary-light-text-desktop[desktop] {
                color: var(--primary-light-color);
            }

            .primary-light-text-s {
                font-size: var(--a-font-size-s);
                color: var(--primary-light-color);
            }

            .primary-light-text-m {
                font-size: var(--a-font-size-m);
                color: var(--primary-light-color);
            }

            .primary-light-text-l {
                font-size: var(--a-font-size-l);
                color: var(--primary-light-color);
            }

            .primary-light-text-xl {
                font-size: var(--a-font-size-xl);
                color: var(--primary-light-color);
                font-family: var(--light-font);
            }

            .primary-light-text-xxl {
                font-size: var(--a-font-size-xxl);
                color: var(--primary-light-color);
            }

            .primary-light-text-xxxl {
                font-size: var(--a-font-size-xxxl);
                color: var(--primary-light-color);
                font-family: var(--thin-font);
            }
            /*secondary text css*/

            .secondary-dark-text-xs {
                font-size: var(--a-font-size-xs);
                color: var(--secondary-dark-color);
            }

            .secondary-dark-text-s {
                font-size: var(--a-font-size-s);
                color: var(--secondary-dark-color);
            }

            .secondary-dark-text-m {
                font-size: var(--a-font-size-m);
                color: var(--secondary-dark-color);
            }

            .secondary-dark-text-l {
                font-size: var(--a-font-size-l);
                color: var(--secondary-dark-color);
            }

            .secondary-dark-text-xl {
                font-size: var(--a-font-size-xl);
                color: var(--secondary-dark-color);
            }

            .secondary-dark-text-xxl {
                font-size: var(--a-font-size-xxl);
                color: var(--secondary-dark-color);
            }

            .secondary-dark-text-xxxl {
                font-size: var(--a-font-size-xxxl);
                color: var(--secondary-dark-color);
            }

            .secondary-light-text-xs {
                font-size: var(--a-font-size-xs);
                color: var(--secondary-light-color);
            }

            .secondary-light-text-s {
                font-size: var(--a-font-size-s);
                color: var(--secondary-light-color);
            }

            .secondary-light-text-m {
                font-size: var(--a-font-size-m);
                color: var(--secondary-light-color);
            }

            .secondary-light-text-l {
                font-size: var(--a-font-size-l);
                color: var(--secondary-light-color);
            }

            .secondary-light-text-xl {
                font-size: var(--a-font-size-xl);
                color: var(--secondary-light-color);
            }

            .secondary-light-text-xxl {
                font-size: var(--a-font-size-xxl);
                color: var(--secondary-light-color);
            }

            .secondary-light-text-xxxl {
                font-size: var(--a-font-size-xxxl);
                color: var(--secondary-light-color);
            }
            /* outstanding text CSS */

            .outstanding-text-xs {
                font-size: var(--a-font-size-xs);
                color: var(--outstanding-color);
            }

            .outstanding-text-s {
                font-size: var(--a-font-size-s);
                color: var(--outstanding-color);
            }

            .outstanding-text-m {
                font-size: var(--a-font-size-m);
                color: var(--outstanding-color);
            }

            .outstanding-text-l {
                font-size: var(--a-font-size-l);
                color: var(--outstanding-color);
            }

            .outstanding-text-xl {
                font-size: var(--a-font-size-xl);
                color: var(--outstanding-color);
            }

            .outstanding-text-xxl {
                font-size: var(--a-font-size-xxl);
                color: var(--outstanding-color);
            }

            .outstanding-text-xxxl {
                font-size: var(--a-font-size-xxxl);
                color: var(--outstanding-color);
            }
            /* outstanding text CSS */
            /* position related styling */

            .position-relative {
                position: relative;
            }

            .position-absolute {
                position: absolute;
            }

            .position-absolute-cover {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
            }

            .position-submerged {
                position: fixed;
                position: -webkit-sticky;
                bottom: -2%;
                right: 0%;
                left: 0%;
            }

            :dir(rtl) .position-absolute-cover {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }

            :dir(rtl) .position-submerged {
                position: fixed;
                position: -webkit-sticky;
                bottom: -2%;
                left: 0%;
                right: 0%;
            }
            /*visiblity related class */

            .display {
                display: none;
            }

            .display-block {
                display: block;
            }

            .display-flex {
                display: flex;
            }

            .display-inline {
                display: inline;
            }
            /*Disabled black color*/

            .disabled-dark-color {
                color: var(--disabled-dark-color);
            }
            /*Disabled white color*/

            .disabled-light-color {
                color: var(--disabled-light-color);
            }
            /*Notification text color*/

            .warning-color {
                color: var(--warning-label-color);
            }

            .critical-color {
                color: var(--critical-label-color);
            }

            .success-color {
                color: var(--success-label-color);
            }

            .info-color {
                color: var(--info-label-color);
            }

            .outstanding-color {
                color: var(--outstanding-color);
            }

            .credited-text-color {
                color: var(--chart-circle-color-light-1);
            }
            /*Notification text color*/
            /*Special phrase text*/

            .special-light-phrase-text-xs {
                color: var(--primary-light-color);
                font-size: var(--a-font-size-xl);
                font-family: var(--light-font);
            }
            /*Special phrase text Amount*/

            .special-dark-Amount-text-xs {
                color: var(--primary-dark-color);
                font-size: var(--a-font-size-xl);
                font-family: var(--medium-font);
            }

            .special-colored-Amount-text-xs {
                color: var(--critical-label-color);
                font-size: var(--a-font-size-xl);
                font-family: var(--medium-font);
            }
            /*input Validation Messages style*/

            .input-validation-message {
                font-size: var(--a-font-size-xs);
                color: var(--critical-label-color);
            }
            /*Success-failure text style*/

            .success-failure-text {
                font-size: var(--a-font-size-xxl);
                color: var(--primary-dark-color);
                font-family: var(--light-font);
            }
            /*Horizontal Scroll content*/

            .horizontal-scroll {
                overflow-x: auto;
                white-space: nowrap;
            }

            .horizontal-scroll[phone]::-webkit-scrollbar {
                height: 0px;
                background: transparent;
            }

            .horizontal-scroll[tablet]::-webkit-scrollbar {
                height: 0px;
                background: transparent;
            }

            .profile-image-wrapper {
                background: var(--backgroud-scondary);
                width: 112px;
                height: 112px;
                border-radius: 50%;
            }


            .chips-option-unSelected {
                background-color: transparent;
                color: var(--disabled-dark-color);
                padding: 7px 14px 8px;
                margin: 4px 8px 4px 0;
                font-size: var(--a-font-size-m);
                display: inline-block;
                border: 1px solid var(--outline-divider-color);
                border-radius: 51px;
                min-width: 18px;
                text-align: center;
                cursor: pointer;
                outline: none;
            }

            .chips-option-selected {
                background-color: var(--brand-color);
                color: var(--primary-light-color);
                border: 1px solid var(--brand-color);
                cursor: inherit;
            }

            .chips-option-unSelected:last-child {
                margin-right: 0;
            }

            :dir(rtl) .chips-option-unSelected:last-child {
                margin-left: 0;
            }

            paper-slider {
                --paper-slider-knob-color: var(--primary-light-color);
                --paper-slider-active-color: var(--brand-color);
                --paper-slider-pin-color: var(--brand-color);
                --paper-slider-knob-start-border-color: var(--primary-light-color);
                --paper-slider-pin-start-color: var(--brand-color);
                --paper-slider-height: 1px;
                --paper-slider-secondary-color: var( --secondary-light-color);
            }

            .brand-icon {
                --iron-icon-width: 48px;
                --iron-icon-height: 48px;
                --iron-icon-fill-color: var(--brand-color);
            }

            @media (min-width: 480px) {
                .divider-cell {
                    border-right: 1px solid var(--divider-color);
                }
                :dir(rtl) .divider-cell {
                    border-left: 1px solid var(--divider-color);
                }
            }

            .dynamic-Container[extra-wide-layout] {
                padding-top: 0;
                background-color: var(--primary-light-color);
            }

            .dynamic-Container[wide-layout] {
                padding-top: 0;
                background-color: var(--primary-light-color);
            }

            .dynamic-Container {
                background-color: var(--background-primary);
            }

            .page-container[extra-wide-layout],
            .page-container[wide-layout] {
                background-color: var(--background-primary);
                border-radius: 2px;
               padding: 0px 16px 16px 16px;
            }
            
            .remove-shadow-mobile[phone]{
                box-shadow: none !important;
            }

            .info-container {
                display: inline;
                padding: 3px 12px 3px 3px;
            }
            /* not reviwed */

            .boxContent {
                padding-left: 48px;
                border-radius: 5px 5px 0 0;
            }

            :dir(rtl) .boxContent {
                padding-right: 48px;
                border-radius: 5px 5px 0 0;
            }

            .boxContainer {
                display: inline-block;
                background-color: var(--primary-light-color);
            }

            .zig-zag-bottom-border {
                position: absolute;
                bottom: -14px;
                background-image: var(--triangle);
                background-repeat: repeat-x;
                height: 15px;
                background-size: 15px;
                margin: 0;
                left: 0;
                width: 100% !important;
            }

            .container-left-padding {
                padding-left: 8px;
            }

            .more-menu,
            .position-r-t {
                position: absolute;
                right: 8px;
                top: 8px;
            }

            .close-button {
                position: absolute;
                right: 0;
                z-index: 1;
            }

            :dir(rtl) .zig-zag-bottom-border {
                position: absolute;
                bottom: -14px;
                background-image: var(--triangle);
                background-repeat: repeat-x;
                height: 15px;
                background-size: 15px;
                margin: 0;
                right: 0;
                left: auto;
                width: 100% !important;
            }

            :dir(rtl) .container-left-padding {
                padding-right: 8px;
            }

            :dir(rtl) .more-menu,
            :dir(rtl) .position-r-t {
                position: absolute;
                left: 8px;
                top: 8px;
                right: auto;
            }

            :dir(rtl) .close-button {
                position: absolute;
                left: 0;
                z-index: 1;
                right: auto;
            }

            .menu {
                position: absolute;
                top: 0;
            }

            .padding-r-l {
                padding-right: 32px;
                padding-left: 32px;
            }

            /* not reviwed */
            /* Upcoming Bills Page*/
            /*Webcomponent template CSS*/
            /*frequently used styles*/

            .position-absolute-t-r {
                position: absolute;
                top: 0;
                right: 0;
            }

            :dir(rtl) .position-absolute-t-r {
                position: absolute;
                top: 0;
                left: 0;
                right: auto;
            }
            /*Need to be reviewed from Nikhil*/

            .circle {
                width: 80px;
                height: 80px;
                border: 1px solid;
                border-radius: 50%;
                background: var(--brand-color);
                color: var(--brand-color)
            }
            /*Need to be reviewed from Nikhil*/

            .boxHeader {
                color: var(--primary-light-color);
            }

            .box-app-box-chip-list {
                font-size: 15em;
                background-color: var(--brand-color-200);
                text-align: center;
                cursor: pointer;
                height: 120px;
                margin: 3px;
                width: 120px !important;
                color: var(--primary-light-color);
                transform: skew(1);
                background-image:  var(--dashboard_bg_texture);
                background-repeat: no-repeat;
                background-position: bottom;
                background-size: 189% 50px;
                transform: scale(1);
                border-radius: 2px;
            }

            .box-app-box-chip-list:hover {
                background-color: var(--outstanding-color);
                transform: scale(1.05);
                transition: transform .1s;
            }

            .flex-1 {
                flex: 1;
            }

            .chart-circle-color-light-1, .random-light-text-1 {
                color: var(--chart-circle-color-light-1) !important
            }

            .random-light-text-2 {
                color: var(--chart-circle-color-light-2);
            }

            .random-light-text-3 {
                color: var(--chart-circle-color-light-3);
            }

            .random-light-text-4 {
                color: var(--chart-circle-color-light-4);
            }

            .random-light-text-5 {
                color: var(--chart-circle-color-light-5);
            }

            .random-light-text-6 {
                color: var(--chart-circle-color-light-6);
            }

            .random-light-text-7 {
                color: var(--chart-circle-color-light-7);
            }

            .background-light-1, .background-light-1.card-background {
                background: var(--chart-circle-color-light-1) !important
            }

            .background-light-2, .background-light-2.card-background {
                background: var(--chart-circle-color-light-2) !important
            }

            .background-light-3, .background-light-3.card-background {
                background: var(--chart-circle-color-light-3) !important
            }

            .background-light-4, .background-light-4.card-background {
                background: var(--chart-circle-color-light-4) !important
            }

            .background-light-5, .background-light-5.card-background {
                background: var(--chart-circle-color-light-5) !important
            }

            .background-light-6, .background-light-6.card-background {
                background: var(--chart-circle-color-light-6) !important
            }

            .background-light-7, .background-light-7.card-background {
                background: var(--chart-circle-color-light-7) !important
            }

            .iron-icon-style-xs {
                width: 18px;
                height: 18px
            }

            .half-width-desktop[desktop] {
                width: 50%;
            }

            .icon-border-light {
                border-radius: 50%;
                background-color: var(--primary-light-color);
            }

            .fab-button-wrapper {
                width: 92px;
            }

            .cursor-pointer {
                cursor: pointer;
            }
            /*Used in Onboarding profile pic*/

            app-fab.icon-cover-app-fab {
                --app-fab-icon-style: {
                    height: 100%;
                    width: 100%;
                    position: absolute;
                }
            }
            /*Used in Onboarding profile pic*/

            /*Accourdian arrow icon display effect on hover*/
            .mainContainer:hover .data-row,
            .mainContainer:focus .data-row,
            .action-button-focused .data-row {
                background-color: var(--background-primary);
            }

            .mainContainer.iron-selected:hover .data-row,
            .mainContainer.iron-selected:focus .data-row {
                background-color: inherit;
            }


            .mainContainer:hover .icon-visible, .mainContainer:hover .list-action-button, .mainContainer.iron-selected:hover .hide-value, .mainContainer.iron-selected .hide-value,
            .mainContainer:focus .icon-visible, .mainContainer:focus .list-action-button, .mainContainer.iron-selected:focus .hide-value, .mainContainer.iron-selected .hide-value,
            .action-button-focused .icon-visible, .action-button-focused .list-action-button, .action-button-focused .hide-value {
                display: flex !important;
            }

            .mainContainer:hover .icon-visible[selected], .mainContainer:hover .hide-value, .mainContainer.iron-selected:hover .list-action-button, .mainContainer.iron-selected .list-action-button,
            .mainContainer:focus .icon-visible[selected], .mainContainer:focus .hide-value, .mainContainer.iron-selected:focus .list-action-button, .mainContainer.iron-selected .list-action-button {
                display: none !important;
            }

            .mainContainer:hover .icon-disable[selected],
            .mainContainer:focus .icon-disable[selected] {
                display: none !important;
            }
            /*Accourdian arrow icon display effect on hover*/


            .item-selected {
                background: var(--brand-color);
                color: var(--primary-light-color);
                font-size: var(--a-font-size-m);
                border-radius: 12px;
            }

            .item-unselected {
                color: var(--secondary-dark-color);
                font-size: var(--a-font-size-m);
                border-radius: 12px;
                border: 1px solid var(--divider-color);
            }

            .overdue {
                background-color: var(--primary-color-but);
                padding: 2px 8px;
            }

            .searchBox {
                border: 1px solid var(--divider-color);
                border-radius: 48px;
                height: 42px;
                width: 250px;
                padding-left: 8px;
            }

            :dir(rtl) .searchBox {
                border: 1px solid var(--divider-color);
                border-radius: 48px;
                height: 42px;
                width: 250px;
                padding-right: 8px;
            }
            /* MicroInteraction oe-input */
           .newInput {
            max-width:25%;
            --paper-input-container-label_-_color: var(--field-unchecked-color);
            --paper-input-container-color:var(--field-unchecked-color);
        }

            .searchBox oe-input {
                --paper-input-container: {
                    padding-bottom: 0;
                    height: 1px;
                };
                --paper-input-container-underline: {
                    display: none;
                    border-bottom-color: var(--primary-light-color);
                };
                --paper-input-container-underline-focus: {
                    display: none;
                };
            }

            paper-item {
                --paper-item: {
                    font-size: inherit;
                }
                --paper-item-body: {
                    font-size: inherit;
                }
            }


            .banner-background-primary {
                background-size: 100% 100%;
                background-image: var(--dashboard_bg);
                background-repeat: no-repeat;
            }

            .image-div-dashboard{
				background-size:100% 100%;
				min-height:212px;
				background-image: var(--dashboard_bg);
			}

			.image-div-dashboard[desktop]{
				min-height:100px;
			}

            .hybrid-screens .banner-background-primary {
                background-position: bottom;
                background-size: 100% 45%;
            }
            /*Disable copy from application*/

            [phone] *:not(input):not(textarea) {
                -webkit-user-select: none;
                /* disable selection/Copy of UIWebView */
                -webkit-touch-callout: none;
                /* disable the IOS popup when long-press on a link */
            }
            /*Disable copy from application*/

            .white-space-initial {              /*Break line into multiple lines*/
                white-space: initial;
            }


            .ie-display {
                display: none !important;
            }

            /*Css hack for IE*/
            @media all and (-ms-high-contrast:none)
            {
                oe-input {
                    --paper-input-container-label: {
                        top: -4px;
                    }
                }
                .height {
                    height:100px;
                }
                paper-item-body {
                    height: 72px;
                }

                paper-item-body.height-auto {
                    height: auto;
                }

                paper-item {
                    --paper-item: {
                        font-size: inherit;
                    }
                }
                .ie-display {
                    display: block !important;
                }

                paper-item-body.ie-preferences {
                    flex-basis: auto !important;
                }

                paper-tooltip {
                    --paper-tooltip-opacity: 1;
                    --paper-tooltip-text-color: var(--secondary-dark-color);
                    --paper-tooltip-background: var(--primary-light-color);
                    box-shadow: 0 2px 4px 0 var(--secondary-dark-color);
                    background: var(--primary-light-color);
                    font-size: var(--tooltip-font-size);
                }
                paper-tooltip .tooltip-container {
                    padding: 8px;
                }
                .ie-remove-overflow-hidden {
                    overflow: visible;
                }

                .dot {
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background: var(--secondary-dark-color);
                }
                .tabwidth
                {
                    width:173px;
                }

                app-swiper {
                    --app-swipe-height: {
                        height: 89px !important;
                    }
                }


                /* enable rtl - start */
                *[dir=rtl] .subheader-wrapper, *[dir=rtl] .mainContent {
                    margin-right: 120px;
                    margin-left: 0 !important;
                }

                *[dir=rtl] .more-button {
                    right:initial;
                    left:0;
                }


                *[dir=rtl] .text-align-right {
                    text-align: var(--align-left);
                }

                *[dir=rtl] .text-align-left {
                    text-align: var(--align-right);
                }
                /* enable rtl - end */

                .set-height-40 {
                    height: 40px;
                    vertical-align: center
                }
                .set-margin-top {
                    margin-top: 26px;
                }
                .set-marginm-top {
                    margin-top: 30px;
                }
                /* To be reviewed with naveen */
                .set-height-85{
                    height: 85px;
                }
                .style-tab {
                    display:table-cell;
                    padding:8px;
                }
            }
            /*Css hack for IE*/

            /*DDA styling*/
            .dda-list-search-screenoff {
                position: absolute;
                overflow: hidden;
                clip: rect(1px 1px 1px 1px);
            }
            /*DDA styling*/
            /* below style is to hide action buttons on hover from devices. Commenting them for issue 268200
            *[isiosandroiddevice] {
                display: none !important;
            }*/

            /* styles to make l2t-paper-slider similar to that of paper-carousel in polymer-1 */
            l2t-paper-slider {
                --paper-slide-font-size: 1px;
                --paper-slide-dot: var(--background-primary);
                --paper-slide-dot-selected: var(--background-secondary);
                --paper-slide-dot-styles: {
                    width: 12px;
                    height: 12px;
                    border-radius: 6px;
                }
            }
            l2t-paper-slider paper-slide {
                overflow-x: unset;
            }

            /* Below styles need to place is required file - when found solution for shadow dom styling - start*/
            /* Styles for deposit flow - app-swiper*/
            .deposit-card-paper-card {
                background-color: var(--card-color-3);
                border-radius: 8px;
                color: var(--primary-light-color);
                min-width: 300px;
                padding: 8px;
                max-width: 350px;
            }
            .deposit-card-more-option{
                background-color: var(--primary-light-color);
                border-radius: 0 6px 6px 0;
                left: 8px;
            }

            :dir(rtl) .deposit-card-more-option{
                background-color: var(--primary-light-color);
                border-radius: 0 6px 6px 0;
                right: 8px;
            }

            /* Styles for loan flow - app-swiper*/
            .loan-card-paper-card {
                background-color: var(--card-color-3);
                border-radius: 8px;
                color: var(--primary-light-color);
                min-width: 300px;
                padding: 8px;
                max-width: 350px;
            }

            .drop-down-options {
                right: 0;
                top: 40px;
                box-shadow: 0 0 2px 0 var(--tabs-box-shadow-first);
                z-index: 1;
            }

            .card-close-button {
                padding: 10px;
            }

            .drop-down-list-container {
                max-height: 151px;
                overflow: auto;
            }            
            
            .loan-card-more-option{
                background-color: var(--primary-light-color);
                margin-top: -8px;
                margin-bottom: -8px;
                margin-right: -8px;
                border-bottom-right-radius: 8px;
                border-top-right-radius: 8px;
            }
            :dir(rtl) .loan-card-more-option{
                background-color: var(--primary-light-color);
                margin-top: -8px;
                margin-bottom: -8px;
                margin-left: -8px;
                border-bottom-right-radius: 8px;
                border-top-right-radius: 8px;
            }
            .chips-option-unSelected.chipTab{
                font-size: var(--a-font-size-s);
                font-family: var(--regular-font);                    
                border-radius: 17px;
                background-position: center;
                transition: background 0.5s;
            }
            .chips-option-unSelected.chipTab:hover {
                background: var(--background-secondary) radial-gradient(circle, transparent 1%, var(--background-secondary) 1%) center/15000%;
                opacity:0.9;
            }
            .chips-option-unSelected.chipTab:active {
                background-color:var(--ripple-color);
                background-size: 100%;
                transition: background 0s; 
            }
            /* Styles for mirroring direction icons like left and right*/
            :dir(rtl) .action-btn-rtl{
                transform: rotate(180deg);
            }
            /* Below styles need to place is required file - when found solution for shadow dom styling - end*/
           
            /*Styles for accounts list starts here*/
            .account-id, .available-balance-text {
                position:relative;
                top:4.3px;
            }

            .footer-menu-overlay {
                z-index: 3;
                background-color: var(--disabled-dark-color);
                position: fixed;
                width: 100%;
                bottom: 0;
                top: 0;
                opacity: 0;
                animation: .3s ease 0s normal forwards 1 animateOpacity;
            }            

            @keyframes animateOpacity {
                0%   { opacity: 0; }
                100% { opacity: 1; }
              }
            /*Styles for accounts list ends here*/ 

            .outlineOffset:focus-visible{
                outline: 2px auto var(--DDA-outline-color);
                outline-offset: 2px !important;
            }
			.padding-left-cal-17{
				padding-left:calc(17.5%)
			}
            .classSubject{
                font-size : 13px;
                color: var(--primary-dark-color);
              }
               .paddingTopMails{
                padding-bottom : 11px !important;
              }
            .selectedFolderListing{
                background: #F3F3F3 !important;
            }
            /*Added for mobile mails listing*/
            .width-auto {
                width:auto !important;
            }
            .padding-margin-right-zero {
                padding-right:0px;
                margin-right:0px;
            }
            /*********/

            /* Add styles for polymer sections when wavemaker application is loaded as MFE */
            .activeWMApp #index_HeaderPanel {
                --paper-scroll-header-panel-header-container: {
                    z-index: 99;
                }
            }
            .activeWMApp .burger-menu-wrapper {
                z-index: 99;
            }
			.header-style {
				color: #2E2E2E;
				font-weight: bold;
			}
			.subHeader-style {
				color: #2E2E2E;
				font-family: 'Nunito-Regular';
			}
			.links-style {
				color: #0072D8;
				font-weight: bold;
			}
			oe-i18n-msg {
                font-family:  var(--regular-font) !important;
				--paper-font-common-base: {
					font-family: var(--regular-font) !important;
				}
            }			
        </style>

        /*  <iron-media-query query="(max-width: 479px)" query-matches="{{phone}}"></iron-media-query>
        <iron-media-query query="(min-width: 480px) and (max-width: 839px)" query-matches="{{tablet}}"></iron-media-query>
        <iron-media-query query="(min-width: 840px)" query-matches="{{desktop}}"></iron-media-query> */
    </template>`;

AppStyles.register("app-styles");
